<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card :title="`Ubah Ukuran '${formData.size}'`" :loading="cardloading">
        <template slot="action">
          <c-button :to="{name:'sizes'}" variant="outline-primary">Kembali</c-button>
          <c-button v-if="!cardloading" :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-input list="code-list" label="Kode" v-model="formData.code" :rules="{required: true}"></form-input>
          <datalist id="code-list">
            <option v-for="(value,key) in code_datasets" :key="key">{{value.code}}</option>
          </datalist>
          <form-input label="Ukuran" v-model="formData.size" :rules="{required: true}"></form-input>
          <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox>
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormCheckbox from '@/utils/components/FormCheckbox.vue'

export default {
  components: { CCard, FormInput, CForm, CButton, FormCheckbox },
  data(){
    const formData = {
      code:'',
      size:'',
      is_active: true
    }
    return {
      formData,
      isloading: false,
      cardloading: true,
      code_datasets:[]
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = vm.formData
      formData.is_active = formData.is_active ? 1 : 0
      this.$http.put(`v1/sizes/update`,formData,{params:{id:vm.$route.params.id,name:formData.size}}).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'sizes'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    },
    getData(){
      const vm = this
      vm.cardloading = true
      vm.$http.get(`v1/sizes/show`,{params:{id:vm.$route.params.id}}).then(({data}) => {
        vm.formData = {
          code: data.code,
          size: data.size,
          is_active: data.is_active ? true : false
        }
        vm.cardloading = false
      })
    },
    async getDatasets(){
      const { data } = await this.$http.get(`v1/sizes/code`)
      this.code_datasets = data
    }
  },
  created(){
    Promise.all([
      this.getData(),
      this.getDatasets()
    ])
  }

}
</script>

<style>

</style>